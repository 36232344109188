<template>
  <div class="flex-1">
    <a-row class="mgt-20 mgl-20">
      <a-col :span="24">
        <span class="ant-input_2"><a-input
            v-model="query.keyword"
            style="width: 330px;"
            allowClear
            @change="query.requestPage = 1,getList()"
            placeholder="请输入合伙人姓名/电话/佣金订单编号"
            class="mgr-20"
          >
            <i
              slot="prefix"
              class="meiye-icon meiye-sousuo"
            ></i></a-input></span>
        <!-- <a-range-picker style="width:280px" class="mgr-20 mgb-20" v-model="dateRange" @change="onChangeTime" />
        <a-select class="mgr-20 mgb-20 width-min-200" allowClear v-model="query.institution_id" @change="query.requestPage = 1,getList()" placeholder="全部门店">
          <a-select-option :value="undefined">全部门店</a-select-option>
          <a-select-option v-for="it in shopList" :key="it.id" :value="it.id">
            {{ it.name }}
          </a-select-option>
        </a-select>-->
        <!-- <a-select class="mgr-20 mgb-20 width-min-200" allowClear v-model="query.status" @change="query.requestPage = 1,getList()" placeholder="全部状态">
          <a-select-option :value="undefined">全部状态</a-select-option>
          <a-select-option :value="0">待结算</a-select-option>
          <a-select-option :value="1">已结算</a-select-option>
          <a-select-option :value="2">已作废</a-select-option>
        </a-select> 
        <a-button type="primary" @click="query.requestPage = 1,getList()">查询</a-button>
        <a-button class="mgl-12" type="link" @click="onReset">重置</a-button>-->
      </a-col>
    </a-row>
    <div class="mgb-12 mgt-12">
      <table-filter
        :flitList="flitList"
        :isAllLength="false"
        :isShowAll="true"
        @transferson="clearfliterSingle"
      ></table-filter>
    </div>
    <a-table
      :customRow="rowClick"
      :scroll="{x:'100%',y:$utils.getfullHeight(380)}"
      class="table-manage"
      @change="tableChanged"
      :pagination="table.pagination"
      row-key="member_id"
      :loading="table.loading"
      :columns="config.bonus.columns_a"
      :data-source="table.tableData"
    >
      <template slot="_time">
        <div class="cursor-pointer">
          <a-popover
            :title="null"
            v-model="visibleTime"
            trigger="click"
            placement="bottomLeft"
          >
            <template slot="content">
              <a-range-picker
                style="width:280px"
                v-model="dateRange"
                @change="onChangeTime"
              />
            </template>
            <span>
              <i
                style="font-size:15px;color:#9EA6A4"
                class="meiye-icon meiye-riqi"
              ></i>
              收款时间
              <i
                style="font-size:9px"
                class="meiye-icon meiye-xiala"
              ></i>
            </span>
          </a-popover>
        </div>
      </template>
      <template slot="_shop">
        <div class="cursor-pointer">
          <a-popover
            :title="null"
            v-model="visibleInstitution"
            trigger="click"
            placement="bottomLeft"
          >
            <template slot="content">
              <a-select
                class="width-min-200"
                optionFilterProp="label"
                showSearch
                allowClear
                v-model="query.institution_id"
                @change="visibleInstitution=false,query.requestPage = 1,getList()"
                placeholder="全部门店"
              >
                <a-select-option :value="undefined">全部门店</a-select-option>
                <a-select-option
                  v-for="it in shopList"
                  :key="it.id"
                  :value="it.id"
                  :label="it.name"
                >
                  {{ it.name }}
                </a-select-option>
              </a-select>
            </template>
            <span>
              <i
                style="font-size:15px;color:#9EA6A4"
                class="meiye-icon meiye-mendian"
              ></i>
              门店
              <i
                style="font-size:9px"
                class="meiye-icon meiye-xiala"
              ></i>
            </span>
          </a-popover>
        </div>
      </template>
      <template
        slot="_1"
        slot-scope="action, record"
      >
        <a-tooltip placement="top">
          <template slot="title">
            {{record.shop || '--'}}
          </template>
          {{record.shop || '--'}}
        </a-tooltip>
      </template>
      <template
        slot="_12"
        slot-scope="action, record"
      >
        <a-tooltip placement="top">
          <template slot="title">
            {{record.scheme_name || '--'}}
          </template>
          {{record.scheme_name || '--'}}
        </a-tooltip>
      </template>
      <template
        slot="_4"
        slot-scope="action, record"
      >
        <a-tooltip placement="top">
          <template slot="title">
            {{record.partner?.name || '--'}} {{record.partner?.phone || '--'}}
          </template>
          {{record.partner?.name || '--'}} {{record.partner?.phone || '--'}}
        </a-tooltip>
      </template>
      <template
        slot="_5"
        slot-scope="action, record"
      >
        <a-tooltip placement="top">
          <template slot="title">
            {{record.payUser?.realname || '--'}} {{record.payUser?.mobile || '--'}}
          </template>
          {{record.payUser?.realname || '--'}} {{record.payUser?.mobile || '--'}}
        </a-tooltip>
      </template>
      <template slot="_plan">
        <div class="cursor-pointer">
          <a-popover
            :title="null"
            v-model="visiblePlan"
            trigger="click"
            placement="bottomLeft"
          >
            <template slot="content">
              <a-select
                class="width-min-200"
                optionFilterProp="label"
                showSearch
                allowClear
                v-model="query.partner_plan_id"
                @change="visiblePlan=false,query.requestPage = 1,getList()"
                placeholder="全部方案"
              >
                <a-select-option :value="undefined">全部方案</a-select-option>
                <a-select-option
                  v-for="it in schemeList"
                  :key="it.id"
                  :value="it.id"
                  :label="it.scheme_name"
                >
                  {{ it.scheme_name }}
                </a-select-option>
              </a-select>
            </template>
            <span>
              <i
                style="font-size:15px;color:#9EA6A4"
                class="meiye-icon  meiye-fangan"
              ></i>
              合伙人方案
              <i
                style="font-size:9px"
                class="meiye-icon meiye-xiala"
              ></i>
            </span>
          </a-popover>
        </div>
      </template>
      <template slot="_status">
        <div class="cursor-pointer">
          <a-popover
            :title="null"
            v-model="visibleStatus"
            trigger="click"
            placement="bottomLeft"
          >
            <template slot="content">
              <a-select
                class="width-min-200"
                allowClear
                v-model="query.status"
                @change="visibleStatus=false,query.requestPage = 1,getList()"
                placeholder="全部状态"
              >
                <a-select-option
                  v-for="v in queryStatusList"
                  :key="v.status"
                  :value="v.status"
                >{{v.label}}</a-select-option>
              </a-select>
            </template>
            <span>
              <i
                style="font-size:15px;color:#9EA6A4"
                class="meiye-icon  meiye-zhuangtai"
              ></i>
              状态
              <i
                style="font-size:9px"
                class="meiye-icon meiye-xiala"
              ></i>
            </span>
          </a-popover>
        </div>
      </template>

      <template
        slot="_1"
        slot-scope="action, record"
      >
        <a-tooltip placement="top">
          <template slot="title">
            {{record.shop?.shop_abbreviation || '--'}}
          </template>
          {{record.shop?.shop_abbreviation || '--'}}
        </a-tooltip>
      </template>
      <template
        slot="_8"
        slot-scope="action, record"
      >
        <a-button
          size="small"
          class="smallbtn"
          @click.stop="toDetail(record)"
        >查看</a-button>
      </template>
    </a-table>
  </div>
</template>
<script> 
import TableFilter from '@/components/intimate/tableFilter/index'
import storage from 'store'
import config from '../config'
import Msg from '@/components/mrj-menu/msg'
import { partnerChainProfitList, getCurrentList, partnerIndexAll } from '@/api/partner'
export default {
  components: { TableFilter },
  data() {
    return {
      config,
      shopList: [],
      queryStatusList: [
        {
          status: 0,
          label: "待结算",
        },
        {
          status: 1,
          label: "已结算",
        },
        {
          status: 2,
          label: "已作废",
        },
      ],
      flitList: [
        { key: '门店', value: [], paramas: 'institution_id' },
        { key: '合伙人方案', value: [], paramas: 'partner_plan_id' },
        { key: '收款时间', value: [], paramas: 'dateRange' },
        { key: '状态', value: [], paramas: 'status' },
      ],
      dateRange: [],
      schemeList: [],
      visibleInstitution: false,
      visibleStatus: false,
      visibleTime: false,
      visiblePlan: false,
      query: {
        perPage: 10,
        requestPage: 1,
        institution_id: undefined,
        keyword: undefined,
        startTime: undefined,
        endTime: undefined,
        // schemeKeyword: undefined,
        status: undefined,
      },
      table: {
        tableData: [],
        loading: false,
        selectedRowKeys: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      }
    }
  },
  created() {
    this.getList();
    // 获取门店
    getCurrentList({ brand_id: localStorage.getItem('global_brand_id') }).then(res => {
      this.shopList = res.data;
      console.log('this.shopList :>> ', this.shopList);
    })
    partnerIndexAll().then(res => {
      console.log(res.data, "data");
      this.schemeList = res.data
    })
  },
  watch: {
    'query.institution_id': {
      handler(val) {
        console.log('val :>> ', val);
        if (!val) {
          this.flitList[0].value = []
        }
        let str = this.shopList.find(v => v.id == val);
        if (str) {
          this.flitList[0].value = [str.name]
        }
      }
    },
    'query.partner_plan_id': {
      handler(val) {
        console.log('val :>> ', val);
        if (!val) {
          this.flitList[1].value = []
        }
        let str = this.schemeList.find(v => v.id == val);
        if (str) {
          this.flitList[1].value = [str.scheme_name]
        }
      }
    },
    'query.status': {
      handler(val) {
        console.log('val :>> ', val);
        if (!val) {
          this.flitList[3].value = []
        }
        let str = this.queryStatusList.find(v => v.status == val);
        if (str) {
          this.flitList[3].value = [str.label]
        }
      }
    }
  },
  methods: {
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        if (e.paramas == 'dateRange') {
          this.dateRange = [];
          this.query.startTime = undefined;
          this.query.endTime = undefined;
        } else {
          this.query[e.paramas] = undefined
        }
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        this.getList()
      } else {
        // 全部清除
        this.clearfliterAll()
      }
    },
    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList = [
        { key: '门店', value: [], paramas: 'institution_id' },
        { key: '合伙人方案', value: [], paramas: 'partner_plan_id' },
        { key: '收款时间', value: [], paramas: 'dateRange' },
        { key: '状态', value: [], paramas: 'status' },
      ]
      this.dateRange = [];
      this.query = {
        perPage: 10,
        requestPage: 1,
        institution_id: undefined,
        keyword: undefined,
        startTime: undefined,
        endTime: undefined,
        // schemeKeyword: undefined,
        status: undefined,
      }
      this.getList()
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            this.toDetail(record)
          }
        }
      }
    },
    onReset() {
      this.query.perPage = 10;
      this.query.requestPage = 1;
      this.query.institution_id = undefined;
      this.query.keyword = undefined;
      this.query.status = undefined;
      this.query.startTime = undefined;
      this.query.endTime = undefined;
      this.dateRange = [];
      this.getList()
    },
    onChangeTime(rec, time) {
      this.visibleTime = false;
      console.log('rec :>> ', rec);
      this.query.startTime = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.query.endTime = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
      this.flitList[2].value = [this.query.startTime + '~' + this.query.endTime];
      this.getList()
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        ...self.query
      }
      return partnerChainProfitList(params).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data
        self.table.tableData = data
        self.table.pagination = Object.assign({}, { pageSize: this.query.perPage, current: this.query.requestPage }, {
          total: total
        })
      }).finally(r => {
        self.table.loading = false
      })
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      this.query = Object.assign(this.query, { perPage: Number(pageSize), requestPage: Number(current) })
      this.getList()
    },
    //查看详情
    toDetail(e) {
      this.$emit('onType')
      Msg.$emit('changethirdaryShow', false)
      this.$router.push({ path: this.$route.path, query: { type: 1, id: e.id } })
    },
  },

}
</script>
<style lang="less" scoped>
/deep/.ant-table-fixed-left,
/deep/.ant-table-fixed-right {
  top: 0;
}
</style>