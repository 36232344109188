export default {
  bonus: {
    detail_columns_pay: [
      {
        title: "支付方式",
        dataIndex: "pay_way_txt",
        key: "_0",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
    ],
    detail_columns_money: [
      {
        title: "订单号",
        dataIndex: "order_no",
        key: "_0",
        ellipsis: true,
        width: 240,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "商品ID",
        dataIndex: "product_id",
        key: "_1",
        ellipsis: true,
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "订单商品",
        dataIndex: "product_name",
        key: "_2",
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "支付明细",
        dataIndex: "payDetail",
        key: "_3",
        ellipsis: true,
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "佣金",
        dataIndex: "amount",
        key: "_4",
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
    ],
    columns_a: [
      {
        title: "佣金订单编号",
        dataIndex: "sn",
        key: "_0",
        ellipsis: true,
        width: 230,
        scopedSlots: { customRender: "_0" },
      },
      {
        slots: { title: "_shop" },
        dataIndex: "shop",
        width: 200,
        key: "_1",
        ellipsis: true,
        scopedSlots: { customRender: "_1" },
      },
      {
        slots: { title: "_plan" },
        dataIndex: "partnerPlan",
        key: "_12",
        width: 200,
        ellipsis: true,
        customRender: (str) => str?.scheme_name || "--",
        scopedSlots: { customRender: "_12" },
      },
      {
        title: "订单收款金额",
        dataIndex: "total_amount",
        width: 120,
        key: "_2",
        customRender: (str) => "¥" + str || "--",
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "佣金",
        dataIndex: "commission_amount",
        width: 100,
        customRender: (str) => "¥" + str || "--",
        key: "_3",
        ellipsis: true,
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "合伙人",
        dataIndex: "partner",
        width: 180,
        key: "_4",
        // customRender: (str) => str?.name + " " + str?.phone,
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "收款客户",
        dataIndex: "payUser",
        key: "_5",
        width: 180,
        // customRender: (str) => str?.realname + " " + str?.mobile,
        ellipsis: true,
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "类型",
        dataIndex: "type",
        width: 80,
        key: "_6",
        ellipsis: true,
        scopedSlots: { customRender: "_6" },
      },
      {
        // title: "收款时间",
        slots: { title: "_time" },
        dataIndex: "pay_time",
        key: "_9",
        width: 180,
        ellipsis: true,
        scopedSlots: { customRender: "_9" },
      },
      {
        title: "结算时间",
        dataIndex: "settlement_time",
        key: "_10",
        customRender: (str) => str || "--",
        width: 180,
        ellipsis: true,
        scopedSlots: { customRender: "_10" },
      },
      {
        slots: { title: "_status" },
        // title: "状态",
        dataIndex: "statusText",
        width: 100,
        key: "_11",
        ellipsis: true,
        scopedSlots: { customRender: "_11" },
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "_8",
        width: 100,
        fixed: "right",
        scopedSlots: { customRender: "_8" },
      },
    ],
  },
  brokerage: {
    detail_columns: [
      {
        title: "合伙人",
        dataIndex: "name",
        key: "_0",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "消费股数量",
        dataIndex: "subscribed_shares",
        key: "_1",
        ellipsis: true,
        customRender: (str) => str + "股" || "--",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "预计分红",
        dataIndex: "predict_dividend_amount",
        key: "_2",
        ellipsis: true,
        customRender: (str) => "¥" + str || "¥ 0.00",
        scopedSlots: { customRender: "_2" },
      },
    ],
    columns_a: [
      {
        title: "分红订单编号",
        dataIndex: "sn",
        key: "_0",
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: "_0" },
      },
      {
        dataIndex: "shop_abbreviation",
        key: "_1",
        width: 200,
        ellipsis: true,
        slots: { title: "_shop" },
        customRender: (str) => str || "--",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "合伙人方案",
        dataIndex: "scheme_name",
        key: "_10",
        ellipsis: true,
        scopedSlots: { customRender: "_10" },
      },
      {
        title: "预计分红",
        dataIndex: "predict_dividend_amount",
        key: "_2",
        customRender: (str) => "¥" + str || "0",
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "实际分红",
        dataIndex: "actual_dividend_amount",
        key: "_3",
        ellipsis: true,
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_3" },
      },
      {
        dataIndex: "dividend_month",
        key: "_4",
        ellipsis: true,
        slots: { title: "_month" },
        scopedSlots: { customRender: "_4" },
      },
      {
        dataIndex: "status_text",
        key: "_6",
        slots: { title: "_status" },
        scopedSlots: { customRender: "_6" },
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "_8",
        width: 100,
        scopedSlots: { customRender: "_8" },
      },
    ],
  },
};
