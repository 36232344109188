<!--
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-04-21 09:51:05
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-22 04:51:17
-->

<template>
  <div>
    <template v-if="activeTabsKey=='合伙人佣金'">
      <div class="page-list" v-if="showType">
        <Bonus @onType="onType"></Bonus>
      </div>
      <BonusDetail @onCancel="onCancel" v-else></BonusDetail>
    </template>
    <div class="page-list" v-if="activeTabsKey=='合伙人分红'">
      <Brokerage></Brokerage>
    </div>
  </div>
</template>
<script> 
import Bonus from './components/bonus.vue'
import Brokerage from './components/brokerage.vue'
import BonusDetail from './components/bonusDetail.vue'
export default {
  components: {
    Bonus, Brokerage, BonusDetail
  },
  data() {
    return {
      showType: true,
    }
  },
  props: {
    activeTabsKey: { // /partner/partnerMenuEarnings/commission  
      default: '合伙人佣金',
      type: String
    }
  },
  created() {
    console.log('activeTabsKey :>> ', this.activeTabsKey);
  },
  methods: {
    onCancel() { this.showType = true; },
    onType() { this.showType = false; },
  }
}
</script>
<style lang="less" scoped>
.page-list {
  background: #fff;
  margin: 18px;
  border-radius: 2px;
  height: calc(100vh - 137px);
  overflow: hidden;
  overflow-y: auto;
}
</style>