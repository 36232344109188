<!--
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-04-11 10:26:28
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-22 04:59:22
-->
<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div class="mrj-layout-tabs-custom flex align-item-center" style="z-index:1;position: relative;">
      <div @click="changeRoute" class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer">
        <div type="left" class="leftarrow" :class="imgflag?'leftarrow_hover':''" @mouseenter="imgflag=true" @mouseleave="imgflag=false">
          <i class="meiye-icon meiye-fanghui"></i>
        </div>
        <span @mouseenter="imgflag=true" @mouseleave="imgflag=false">{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">详情</span>
    </div>
    <div class="height100 flex flex-column">
      <div class="padding-wai">
        <div class="padding-item">
          <div class="detail-title">
            <a-avatar shape="square" class="img" :src="detailInfo.img" />
            <div>
              <div style="margin-bottom:4px">
                <span class="title">{{detailInfo.partner?.name || '--'}} </span>
                <span style="margin-left:8px" size="small" :class="detailInfo.status == 1 ? 'mrj-span-tag-green' : 'mrj-span-tag-red'">{{detailInfo.statusText}}</span>
              </div>
              <div class="text"><i class="meiye-icon meiye-shouji" style="color:#819190"></i>  {{detailInfo.partner?.phone || '--'}} <a-divider type="vertical" style="height: 9px; background-color: #DDE8E7;" /><span>{{detailInfo.shop?.shop_abbreviation || '--'}}</span></div>
            </div>
          </div>
          <a-divider />
          <h2 class="twoh2">
            <span class="tpointer"></span>
            <span class="ttxt">基础信息</span>
          </h2>
          <a-row class="mgl-10">
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">佣金订单编号</span>
                <span class="rText">{{detailInfo.sn || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">结算时间</span>
                <span class="rText">{{detailInfo.settlement_time || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">结算方式</span>
                <span class="rText">{{detailInfo.settlementTypeText || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">合伙人方案</span>
                <span class="rText">{{detailInfo.partnerPlan?.scheme_name || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">银行卡</span>
                <span class="rText">{{detailInfo.bankCard?.bank_card_no || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">开户行</span>
                <span class="rText">{{detailInfo.bankCard?.bank_name || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">持卡人</span>
                <span class="rText">{{detailInfo.bankCard?.name || '--'}}</span>
              </div>
            </a-col>
          </a-row>
          <h2 class="twoh2">
            <span class="tpointer"></span>
            <span class="ttxt">佣金信息</span>
          </h2>
          <div class="mgl-10">
            <a-row>
              <a-col :span="8">
                <div class="item-r-l">
                  <span class="lText">订单客户</span>
                  <span class="rText">{{detailInfo.payUser?.realname || '--'}} {{detailInfo.payUser?.mobile || '--'}}</span>
                </div>
              </a-col>
              <a-col :span="8">
                <div class="item-r-l">
                  <span class="lText">收款金额</span>
                  <span class="r theme-color" style="font-weight: 500;font-family: PingFangSC-Medium, PingFang SC;"> ¥{{detailInfo.total_amount || '0'}}</span>
                </div>
              </a-col>
              <a-col :span="8">
                <div class="item-r-l">
                  <span class="lText">收款时间</span>
                  <span class="rText">{{detailInfo.pay_time || '--'}}</span>
                </div>
              </a-col>
            </a-row>
            <h2 class="twoh1 mgt-12">
              <span class="tpointer"></span>
              <span class="ttxt">佣金明细<span class="mgl-12 mgr-12" style="color:#D2D6D5">|</span>{{ detailInfo.type}} <a-button v-if="$route.query.type==2" @click="editType()" class="smallbtn-icon" ><span class="meiye-icon meiye-moren"></span></a-button></span>
            </h2>
            <a-table class="mgb-30 mgt-10 detailsTable" :loading="table_loading" :pagination="false" row-key="order_no" size="middle" :columns="config.bonus.detail_columns_money" :data-source="detailInfo.commissionOrderDetail">
              <template slot="_3" slot-scope="text, record, index">
                {{setJion(record.payDetail)}}
              </template>
              <template slot="_4" slot-scope="text, record, index">
                ¥{{record.amount || 0.00}}
                <a-button v-if="$route.query==2" @click="editAmount(record)" class="smallbtn-icon" ><span class="meiye-icon meiye-moren"></span></a-button>
              </template>
            </a-table>
            <h2 class="twoh1">
              <span class="tpointer"></span>
              <span class="ttxt">支付明细</span>
            </h2>
            <div class="y-row">
              <div class="y-flex y-header">
                <div class="m-w-200">支付方式</div>
                <div class="m-w-200" v-for="(v,s) in detailInfo.payDetail" :key="s">{{v.pay_way_txt}}</div>
              </div>
              <div class="y-flex y-content">
                <div class="m-w-200">支付金额</div>
                <div class="m-w-200" v-for="(b,i) in detailInfo.payDetail" :key="i">¥{{b.money}}</div>
              </div>
            </div>
          </div>
        </div>
        <h5 class="y-flex mgb-20 pl-20 y-foot">佣金: <div class="theme-color mgr-12 mgl-12 font26">¥{{detailInfo.commission_amount || 0}}</div>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import storage from 'store'
import config from '../config'
import Msg from '@/components/mrj-menu/msg'
import { partnerChainProfitDetail } from '@/api/partner'
export default {
  inject: ['reload'],
  data() {
    return {
      config,
      detailInfo: {},
      table_loading: false,
      imgflag: false,
    }
  },
  created() {
    let self = this
    const { id } = self.$route.query
    const data = {
      id
    }
    partnerChainProfitDetail(data).then(res => {
      self.detailInfo = res.data;
    })
  },
  methods: {
    setJion(v) {
      let arr = []
      v.forEach(element => {
        let str = element.pay_way_txt + ': ¥' + element.money;
        arr.push(str)
      });
      return arr.join(' ')
    },
    changeRoute() {
      this.$emit('onCancel')
      console.log('this.$route.fullpath :>> ', this.$route.fullpath);
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
      this.$router.push({ path: this.$route.fullpath });
    }
  }
};
</script>

<style scoped lang="less">
.padding-wai {
  margin: 18px;
  height: calc(100vh - 138px);
  background: #fff;
  overflow-y: auto;
  padding-bottom: 100px;
}
.padding-item {
  padding: 40px 0 0 35px;
}
.twoh2 {
  display: flex;
  margin-top: 20px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 8px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 600;
    color: @fontColor1;
    line-height: 25px;
  }
}
.twoh1 {
  display: flex;
  margin-bottom: 12px;
  align-items: baseline;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 8px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 20px;
  }
}
.item-r-l {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  .rText {
    color: @fontColor1;
  }
  .lText {
    color: rgba(45, 56, 53, 0.8);
    width: 123px;
    display: block;
  }
}
.y-flex {
  display: flex;
  align-items: center;
}
.y-row {
  font-size: 14px;
  font-weight: 400;
  color: @fontColor3;
  .y-header {
    height: 44px;
    line-height: 44px;
    padding-left: 24px;
    font-weight: 500;
    color: @fontColor1;
    border-top: 1px solid #a6dad7;
    background: rgba(236, 244, 243, 0.41);
    box-shadow: inset 0px -2px 0px 0px rgba(193, 212, 211, 0.31);
  }
  .y-content {
    height: 50px;
    padding-left: 24px;
    font-weight: 400;
    line-height: 50px;
    background: rgba(170, 170, 170, 0);
    border-bottom: 1px solid #e8edec;
  }
  .m-w-200 {
    min-width: 200px;
  }
}
.font26 {
  font-size: 26px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
}
.detail-title {
  display: flex;
  align-items: center;
  .img {
    width: 60px;
    height: 60px;
    background: #cbe3e1;
    border-radius: 8px;
    margin-right: 18px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    color: @fontColor1;
  }
}
.pl-20 {
  margin-top: 11px;
  border-top: 1px solid #d8eceb;
  padding-left: 42px;
  padding-top: 20px;
}
/deep/ .detailsTable .ant-table .ant-table-thead > tr > th {
  padding: 1px 8px !important;
}
/deep/
  .detailsTable
  .ant-table
  .ant-table-thead
  .ant-table-header-column
  .ant-table-column-title {
  color: @fontColor1 !important;
  font-weight: 500;
}
/deep/ .detailsTable .ant-table .ant-table-tbody > tr > td {
  // padding: 0px 0 0 0px !important;
  color: @fontColor3 !important;
  font-weight: 400;
  height: 54px;
}
/deep/ .detailsTable .ant-table-row > td:nth-child(1),
/deep/ .detailsTable .ant-table-thead > tr:first-child > th:first-child {
  padding-left: 24px !important;
}
/deep/ .detailsTable .ant-table-tbody > tr:first-child > th:first-child {
  padding-left: 24px !important;
}
/deep/ .detailsTable .ant-table .ant-table-tbody > tr:hover {
  td {
    background-color: #fff !important;
  }
}
.y-foot {
  position: fixed;
  bottom: -1px;
  padding-bottom: 16px;
  padding-top: 16px;
  background: #fff;
  width: calc(100% - 217px)
}
</style>