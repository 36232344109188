<template>
  <div class="flex-1">
    <div class="mgt-30 height42" style="text-align:center">
      <a-radio-group v-model="showStatus" class="lable_radiogroup">
        <a-radio-button style="width: 220px;" :value="1"> 分红订单 </a-radio-button>
        <a-radio-button style="width: 220px;" :value="2"> 合伙人分红 </a-radio-button>
      </a-radio-group>
    </div>
    <div v-if="showStatus==1">
      <div class="mgb-12 mgt-12">
        <table-filter :flitList="flitList" :isAllLength="false" :isShowAll="true" @transferson="clearfliterSingle"></table-filter>
      </div>
      <a-table class="table-manage mgt-12" @change="tableChanged" :pagination="table.pagination" row-key="id" :loading="table.loading" :columns="config.brokerage.columns_a" :data-source="table.tableData">
        <template slot="_shop">
          <div class="cursor-pointer">
            <a-popover :title="null" v-model="visibleInstitution" trigger="click" placement="bottomLeft">
              <template slot="content">
                <a-select class="width-min-200" optionFilterProp="label" showSearch allowClear v-model="query.shop_id" @change="visibleInstitution=false,query.page = 1,getList()" placeholder="全部门店">
                  <a-select-option v-for="it in shopList" :key="it.id" :value="it.id" :label="it.name">
                    {{ it.name }}
                  </a-select-option>
                </a-select>
              </template>
              <span>
                <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-mendian"></i>
                门店
                <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
              </span>
            </a-popover>
          </div>
        </template>
        <template slot="_month">
          <div class="cursor-pointer">
            <a-popover :title="null" v-model="visibleTime" trigger="click" placement="bottomLeft">
              <template slot="content">
                <a-month-picker allowClear format="YYYY-MM" @change="setMonth" />
              </template>
              <span>
                <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-riqi"></i>
                统计月份
                <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
              </span>
            </a-popover>
          </div>
        </template>
        <template slot="_10" slot-scope="action, record">
          <a-tooltip placement="top">
            <template slot="title">
              {{record.scheme_name || '--'}}
            </template>
            {{record.scheme_name || '--'}}
          </a-tooltip>
        </template>
        <template slot="_status">
          <div class="cursor-pointer">
            <a-dropdown :trigger="['click']">
              <span @click="e => e.preventDefault()">
                <i style="font-size:15px;color:#9EA6A4" class="meiye-icon  meiye-zhuangtai"></i>
                状态
                <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
              </span>
              <a-menu slot="overlay">
                <a-menu-item v-for="v in queryStatusList" @click="query.page = 1,query.status = v.status,getList()">
                  {{v.label}}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </template>
        <template slot="_8" slot-scope="action, record">
          <a-button size="small" class="mgr-12 smallbtn" @click="toDetail(record)">查看</a-button>
        </template>
      </a-table>
    </div>
    <template v-if="showStatus==2">
      <Detail></Detail>
    </template>
    <a-modal  class="ak-modal-table-tb" :visible="edit_modal.visible" :footer="null" @cancel="edit_modal.visible=false" :width="edit_modal.data.dividend_order_rule.length>2?950:680">
      <template slot="title">
        <span>{{edit_modal.title}}</span>
        <span class="mgl-12" size="small" :class="edit_modal.status != 1 ? 'mrj-span-tag-green' : 'mrj-span-tag-red'">{{edit_modal.status_text}}</span>
      </template>
      <a-form-model class="mgt-10" :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" labelAlign="left">
        <a-row type="flex" justify="space-between" align="bottom">
          <a-col :span="12">
            <a-form-model-item label="门店分红订单编号">
              {{edit_modal.data.sn}}
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="消费股">
              {{edit_modal.data.scheme_name}}
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="订单产生时间" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          {{edit_modal.data.created_at}}
        </a-form-model-item>

        <h2 class="twoh2">
          <span class="tpointer"></span>
          <span class="ttxt">分红项目营收</span>
        </h2>
        <a-form-model-item label="统计月份" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          {{edit_modal.data.dividend_month||'--'}}
        </a-form-model-item>
        <div class="item_cards mgb-20">
          <template v-for="v in edit_modal.data.dividend_order_rule">
            <div class="text-center" :key="v.dividend_business_text">
              <div class="toptext">{{v.dividend_business_text}}（元）</div>
              <div class="bottomtext">¥{{v.total_amount || 0.00}}</div>
            </div>
            <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;" />
          </template>
          <div class="text-center">
            <div class="toptext">预计分红（元）</div>
            <div class="bottomtext">¥{{edit_modal.data.predict_dividend_amount || 0.00}}</div>
          </div>
          <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;" />
          <div class="text-center">
            <div class="toptext">实际分红（元）</div>
            <div class="bottomtext">¥{{edit_modal.data.actual_dividend_amount || 0.00}}</div>
          </div>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import TableFilter from '@/components/intimate/tableFilter/index'
import config from "../config";
import Detail from "./detail.vue";
import {
  partnerFhOrder,
  partnerFhDetail,
  getCurrentList,
} from "@/api/partner";
export default {
  components: {
    Detail, TableFilter
  },
  data() {
    return {
      config,
      showStatus: 1,
      visibleTime: false,
      flitList: [
        { key: '门店', value: [], paramas: 'shop_id' },
        { key: '统计月份', value: [], paramas: 'dividend_month' },
        { key: '状态', value: [], paramas: 'status' },
      ],
      shopList: [], visibleInstitution: false,
      queryStatusList: [
        {
          status: 1,
          label: "待汇入",
        },
        {
          status: 2,
          label: "已汇入",
        },
      ],
      query: {
        per_page: 10,
        page: 1,
        shop_id: undefined,
        status: undefined,
        dividend_month: undefined,
      },
      edit_modal: {
        title: '编辑',
        status: '',
        status_text: '',
        visible: false,
        type: '1',
        data: {
          id: '',
          scheme_name: '',
          created_at: '',
          sn: '',
          predict_dividend_amount: '',
          actual_dividend_amount: '',
          dividend_month: '',
          dividend_order_rule: ''
        }
      },
      table: {
        tableData: [],
        loading: false,
        selectedRowKeys: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
    };
  },
  created() {
    var now = new Date();
    var nowYear = now.getFullYear();
    var nowMonth = now.getMonth();
    console.log("nowYear :>> ", nowYear);
    console.log("nowMonth :>> ", nowMonth);
    // this.query.dividend_month = nowYear + "-" + nowMonth;
    this.getList();
    // 获取门店
    getCurrentList({ brand_id: localStorage.getItem('global_brand_id') }).then(res => {
      this.shopList = res.data;
      console.log('this.shopList :>> ', this.shopList);
    })
  },
  watch: {
    'query.shop_id': {
      handler(val) {
        console.log('val :>> ', val);
        if (!val) {
          this.flitList[0].value = []
        }
        let str = this.shopList.find(v => v.id == val);
        if (str) {
          this.flitList[0].value = [str.name]
        }
      }
    },
    'query.dividend_month': {
      handler(val) {
        if (!val) {
          this.flitList[1].value = []
        }
        console.log('val :>> ', val);
        if (val) {
          this.flitList[1].value = [val]
        }
      }
    },
    'query.status': {
      handler(val) {
        if (!val) {
          this.flitList[2].value = []
        }
        console.log('val :>> ', val);
        let str = this.queryStatusList.find(v => v.status == val);
        if (str) {
          this.flitList[2].value = [str.label]
        }
      }
    }
  },
  methods: {
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        this.query[e.paramas] = undefined
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        this.getList()
      } else {
        // 全部清除
        this.clearfliterAll()
      }
    },
    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList = [
        { key: '门店', value: [], paramas: 'shop_id' },
        { key: '统计月份', value: [], paramas: 'dividend_month' },
        { key: '状态', value: [], paramas: 'status' },
      ]
      this.query = {
        per_page: 10,
        page: 1,
        shop_id: undefined,
        status: undefined,
        dividend_month: undefined,
      }
      this.getList()
    },
    setMonth(date, dateString) {
      this.visibleTime = false;
      this.query.dividend_month = dateString;
      this.getList();
    },
    // 获取列表
    getList() {
      let self = this;
      self.table.selectedRowKeys = []
      self.table.loading = true;
      const params = {
        ...self.query,
      };
      return partnerFhOrder(params)
        .then((res) => {
          console.log(res.data, "data");
          const { data = [], total = 0 } = res.data;
          self.table.tableData = data;
          self.table.pagination = Object.assign(
            {},
            { pageSize: this.query.per_page, current: this.query.page },
            {
              total: total,
            }
          );
        })
        .finally((r) => {
          self.table.loading = false;
        });
    },
    onSelectChange(selectedRowKeys) {
      console.log(selectedRowKeys);
      this.table.selectedRowKeys = selectedRowKeys;
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      const { field, order } = sorter;
      this.query = Object.assign(this.query, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getList();
    },
    //查看详情
    toDetail(item) {
      partnerFhDetail({ id: item.id }).then(res => {
        this.edit_modal.title = res.data.shop?.shop_abbreviation || '--';
        this.edit_modal.status = res.data.status;
        this.edit_modal.status_text = res.data.status_text;
        this.edit_modal.data.scheme_name = res.data.subscribed_shares?.total_subscribed_shares || '--';
        this.edit_modal.data.created_at = res.data.created_at;
        this.edit_modal.data.dividend_month = res.data.dividend_month;
        this.edit_modal.data.sn = res.data.sn;
        this.edit_modal.data.id = res.data.id;
        this.edit_modal.data.dividend_order_rule = res.data.orderRuleData;
        this.edit_modal.data.actual_dividend_amount = res.data.actual_dividend_amount;
        this.edit_modal.data.predict_dividend_amount = res.data.predict_dividend_amount;
        this.edit_modal.visible = true
      })
    }
  },
};
</script>
<style lang="less" scoped>
.twoh3 {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 20px;
    margin-right: 12px;
  }
}
.item_cards {
  background: #fafcfc;
  border-radius: 8px;
  border: 1px solid @borderColorBase;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 28px;
  padding-bottom: 22px;
  justify-content: space-evenly;
  .toptext {
    font-size: 14px;
    font-weight: 400;
    color: #4d5e59;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .b-r {
    padding-right: 20px;
    border-right: 1px solid @borderColorBase;
    // border-left: 1px solid @primaryColor;
  }
  .bottomtext {
    color: @baseColor11;
    font-size: 22px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    line-height: 26px;
  }
}
/deep/ .ant-tag {
  color: @primaryColor;
  border: 1px solid @primaryColor;
}
.twoh2 {
  display: flex;
  margin-bottom: 7px;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin: 6px 8px 0 0;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 22px;
  }
}
/deep/.ant-row-flex-bottom {
  align-items: center;
}
</style>
