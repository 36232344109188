<template>
  <div class="mgt-20 flex-1">
    <a-row class="mgl-20">
      <a-select class="mgr-12 mgb-20 width-min-200" v-model="dataForm.shop_id" placeholder="选择门店">
        <a-select-option v-for="it in shopList" :key="it.id" :value="it.id">
          {{ it.name }}
        </a-select-option>
      </a-select>
      <a-select class="mgb-20 width-min-200" v-model="dataForm.partner_plan_id" @change="getList" placeholder="全部方案">
        <a-select-option v-for="it in schemeList" :key="it.id" :value="it.id">
          {{ it.scheme_name }}
        </a-select-option>
      </a-select>
      <a-button type="primary" class="mgl-20 table-btn-clk" @click="setDetail">生成合伙人订单</a-button>
    </a-row>
    <!-- :rowClassName='getRowBackGround' -->
    <a-table class="y-table table-manage mgl-20" :rowClassName='getRowBackGround' :scroll="{ x: '100%'}" bordered @change="tableChanged" :pagination="table.pagination" row-key="id" :loading="table.loading" :columns="columns" :data-source="table.tableData">
      <span slot="_star">
        <a-input size="small" class="y-input" v-model="dataForm.partner_search" @pressEnter="getList" placeholder="姓名/电话">
          <i slot="prefix" class="meiye-icon meiye-sousuo theme-color" style="left: -3px;position: relative;"></i>
        </a-input>
        <div class="flex justify-between border-top" style="padding-left:0">
          <div class="header-text flex" style="align-items:center">
            <a-checkbox :disabled="setDisAll()" @change="onChangeCheckboxAll"></a-checkbox>
            <div class="mgl-12">
              <div style="margin-bottom:4px">合伙人</div>
              <div>{{table.pagination.total}} <span style="color: #A4B3B1;">/人</span></div>
            </div>
          </div>
          <div class="header-text">
            <div style="margin-bottom:4px">消费股</div>
            <div>{{table.total_subscribed_shares}} <span style="color: #A4B3B1;">/股</span></div>
          </div>
        </div>
      </span>
      <template slot="user" slot-scope="action, record">
        <div>
          <div class="flex">
            <a-checkbox :checked="checkedList.includes(record.id)" :disabled="setDis(record)" @change="onChangeCheckbox(record,$event)" class="flex" style="align-items:end">
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span> {{record.name || '--'}}</span>
                </template>
                <span class="title-name line-1" style="width:90px;display:block"> {{record.name || '--'}}</span>
              </a-tooltip>
            </a-checkbox>
            <a-tooltip placement="topRight">
              <template slot="title">
                <span> {{record.name || '--'}} <br>{{record.subscribed_shares || 0}}股</span>
              </template>
              <span class="title-name mgl-12  line-1" style="font-size: 14px;width:80px;display:block">{{record.subscribed_shares || 0}}股</span>
            </a-tooltip>
          </div>

          <div class="phone-14" style="margin-left:26px">{{record.phone || '--'}}</div>
          <div class="phone" style="margin-left:26px">档案ID: {{record.uuid || '--'}}</div>
        </div>
      </template>
      <span :slot="'_'+i" v-for="(v,i) in table.tableDateList">
        <div class="header-text" style="margin-bottom:6px;">{{v.dividend_month}}
          <span v-if="v.order_partner_id">
            <a-tooltip placement="bottom">
              <template slot="title">
                <span>编辑实际分红金额</span>
              </template>
              <span class="plus-icon plus-icon-two meiye-icon meiye-moren" @click="editItem(v)"></span>
            </a-tooltip>
          </span>
        </div>
        <div class="w-200">
          <div style="color: #A4B3B1;margin-bottom:6px"><span class="mgr-12">预计分红</span>¥{{v.predict_dividend_amount || 0}}</div>
          <div style="color: #A4B3B1;"><span class="mgr-12">实际分红</span>¥{{v.actual_dividend_amount || 0}}</div>
        </div>
      </span>
      <template :slot="'c_'+i" v-for="(v,i) in table.tableDateList" slot-scope="action, record">
        <div class="p-16-td table-color-success" :class="record.bg_show.includes(v.id)?'table-color-success':'table-color-error'">
          <span :class="reg(record.date_list,v)=='--'?'theme-grey':''" style="font-size: 14px;">{{reg(record.date_list,v)}}</span><br>
          <span :class="regMoney(record.date_list,v)=='0.00'?'theme-grey':'theme-color'">¥{{regMoney(record.date_list,v)}}</span>
        </div>
      </template>
      <template slot="_end" slot-scope="action, record">
        <div class="flex justify-between">
          <div class="header-text" style="text-align:left">
            <div style="margin-bottom:2px;color: #A4B3B1;font-weight: 400;">已选</div>
            <div style="color: @fontColor1;font-size: 16px;">{{checkedList.includes(record.id)?record.bg_show.length:0}}个</div>
          </div>
          <div class="header-text" style="text-align:right">
            <div style="margin-bottom:2px;color: #A4B3B1;font-weight: 400;">合计</div>
            <div class="theme-color" style="font-size: 16px;">¥{{checkedList.includes(record.id)?setAllmoney(record.date_list):0}}</div>
          </div>
        </div>
      </template>
      <template slot="t_end">
        <a-button type="primary" class="mgr-12 table-btn-clk" style="width:140px" @click="settleAccounts()">结算</a-button>
      </template>
    </a-table>
    <a-modal class="ak-modal-table-tb" :visible="edit_modal.visible" :footer="edit_modal.dataMonth.id==0 ? null : undefined" @cancel="edit_modal.visible=false" :width="750">
      <template slot="title">
        <span>{{edit_modal.title}}<a-tooltip placement="bottom">
            <template slot="title">
              生成合伙人分红订单: 依据输入的实际分红金额生成选择统计月份的合伙人分红订单,其中参与分红的合伙人为生成分红订单时已录入的合伙人;
            </template>
            <a-icon :style="{ color: '#819190' }" type="info-circle" />
          </a-tooltip>
        </span>
      </template>
      <a-form-model class="mgt-20 mgb-10" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" labelAlign="left">
        <a-form-model-item label="统计月份">
          <a-month-picker format="YYYY-MM" v-model="dividend_month" @change="setMonth" />
        </a-form-model-item>
        <div class="item_cards mgb-20">
          <div class="text-center">
            <div class="toptext">预计分红
              <a-tooltip placement="top">
                <template slot="title">
                  预计分红: 通过合伙人方案设置的规则统计出的预计分红金额;
                </template>
                <a-icon :style="{ color: '#819190' }" type="info-circle" />
              </a-tooltip>
            </div>
            <div class="bottomtext">¥{{edit_modal.dataMonth.predict_dividend_amount || 0.00}}</div>
          </div>
          <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;" />
          <div class="text-center">
            <div class="toptext">合伙人</div>
            <div class="bottomtext">{{edit_modal.data.partner_count || 0}}</div>
          </div>
          <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;" />
          <div class="text-center">
            <div class="toptext">消费股</div>
            <div class="bottomtext">{{edit_modal.data.subscribed_shares || 0}}</div>
          </div>
        </div>
        <a-row class="mgb-20" type="flex" align="middle">
          <a-col :span="5">
            <div class="left-title" style="color:#6F7270">实际分红<a-tooltip placement="top">
                <template slot="title">
                  实际分红:依据输入的实际分红,计算出参与分红的合伙人每人可分配的金额,该金额将在小程序展示;
                </template>
                <a-icon :style="{ color: '#819190',marginLeft:'8px' }" type="info-circle" />
              </a-tooltip>
            </div>
          </a-col>
          <a-col :span="16">
            <a-input type="number" style="width: 225px" placeholder="输入实际分红金额" suffix="元" v-model="edit_modal.form.actual_dividend_amount"></a-input>
          </a-col>
        </a-row>
        <a-form-model-item label="门店">
          {{edit_modal.data.shop_info?.shop_abbreviation ||'--'}}
        </a-form-model-item>
        <a-form-model-item label="方案">
          {{edit_modal.data.plan_info?.scheme_name}}
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="edit_modal.visible=false">
          取消
        </a-button>
        <a-button type="primary" @click="handleOkEdit">
          确认
        </a-button>
      </template>
    </a-modal>
    <a-modal :visible="money_modal.visible" @ok="setModalVisible" @cancel="money_modal.visible=false" :width="600">
      <template slot="title">
        <span style="font-size: 18px;font-weight: 600;color: #131010;">实际分红</span>
        <span class="mgl-10" style="color: #2D3835;font-size:14px">{{money_modal.data.dividend_month}}</span>
      </template>
      <a-row class="mgb-20" type="flex" align="middle">
        <a-col :span="5">
          <div class="left-title  mgl-12">预计分红<a-tooltip placement="top">
              <template slot="title">
                实际分红:依据输入的实际分红,计算出参与分红的合伙人每人可分配的金额,该金额将在小程序展示;
              </template>
              <a-icon :style="{ color: '#819190',marginLeft:'8px' }" type="info-circle" />
            </a-tooltip>
          </div>
        </a-col>
        <a-col :span="16">
          {{money_modal.data?.predict_dividend_amount || 0}}
        </a-col>
      </a-row>
      <a-row class="mgb-20" type="flex" align="middle">
        <a-col :span="5">
          <div class="left-title  mgl-12">实际分红<a-tooltip placement="top">
              <template slot="title">
                实际分红:依据输入的实际分红,计算出参与分红的合伙人每人可分配的金额,该金额将在小程序展示;
              </template>
              <a-icon :style="{ color: '#819190',marginLeft:'8px' }" type="info-circle" />
            </a-tooltip>
          </div>
        </a-col>
        <a-col :span="16">
          <a-input class="ant-input_1" type="number" style="width: 225px" placeholder="输入实际分红金额" suffix="元" v-model="money_modal.form.actual_dividend_amount"></a-input>
        </a-col>
      </a-row>
    </a-modal>
    <a-modal class="ak-modal-table-tb" title="分红结算" :visible="modal.visible" @ok="setModal" @cancel="modal.visible=false" :width="750">
      <a-form-model class="mgt-10" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" labelAlign="left">
        <a-form-model-item label="合计结算金额">
          <span class="theme-color" style="font-weight:600">¥{{modal.money}}</span>
        </a-form-model-item>
        <a-form-model-item label="结算月份">
          {{getMonthStr(modal.dataMonth,table.tableDateList)}}
        </a-form-model-item>
        <div class="ant-row ant-form-item">
          <div class="ant-col ant-col-5 ant-form-item-label ant-form-item-label-left">
            <span style="color:#6F7270">结算方式</span><a-tooltip placement="topLeft" overlayClassName="spec_tooltip">
              <template slot="title">
                线上:通过银行转账方式结算合伙人分红 <br>
                线下:通过现金及其他方式结算合伙人分红
              </template>
              <a-icon :style="{ color: '#819190',marginLeft:'4px' }" type="info-circle" />
            </a-tooltip>
          </div>
          <div class="ant-col ant-col-16 ant-form-item-control-wrapper">
            <div class="ant-form-item-control">
              <a-radio-group v-model="modal.form.settlement_type">
                <a-radio :value="1">线上</a-radio>
                <a-radio :value="2">线下</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-model-item label="通知方式">系统通知</a-form-model-item>
        <a-form-model-item label="备注原因">
          <a-textarea v-model="modal.form.content" :maxLength="50" placeholder="请输入修改原因，50字已内" :auto-size="{ minRows: 3, maxRows: 5 }" />
        </a-form-model-item>
        <div class="ant-row ant-form-item mgt-20">
          <div class="ant-col ant-col-5 ant-form-item-label ant-form-item-label-left">图片</div>
          <div class="ant-col ant-col-19 ant-form-item-control-wrapper">
            <a-upload name="file" :before-upload="beforeUploadImg" @preview="handlePreview" :action="baseUrl+'/api/v2/tools/upload'" list-type="picture-card" :file-list="fileList" @change="handleChange">
              <div v-if="fileList.length < 10">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <span style="font-size:14px;color:#819190">支持上传多张图片，最多可上传10张图片。 图片支持jpg、jpeg、png格式上传，上传后的图
              片将会自动保存在佣金订单结算备注中</span>
          </div>
        </div>
      </a-form-model>
    </a-modal>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible=false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment';
import config from "../config";
import Detail from "./detail.vue";
import {
  getPlanAll,
  partnerFhChainCreateDetail,
  getCurrentList,
  partnerFhPartnerList,
  partnerFhChainMonthDetail,
  partnerFhChainSave,
  partnerFhChainSettleDividend,
  partnerFhChainCreate
} from "@/api/partner";
import { find, push } from 'store/storages/all';
const columns = [{
  dataIndex: "user",
  key: "user",
  slots: { title: '_star' },
  // fixed: 'left',
  // width: 200,
  scopedSlots: { customRender: 'user' },
}]
const columns_end = [{
  key: "_end",
  slots: { title: 't_end' },
  scopedSlots: { customRender: '_end' },
  width: 200,
  align: 'center',
  fixed: 'right',
}]
export default {
  components: {
    Detail
  },
  data() {
    return {
      config,
      columns, columns_end,
      previewVisible: false,
      previewImage: '',
      baseUrl: process.env.VUE_APP_API_BASE_URL2,
      headers: {
        "Authorization": localStorage.getItem("Access-Token"),
        "Content-Type": 'multipart/form-data'
      },
      showStatus: 1,
      shopList: [],
      schemeList: [],
      checkedList: [],
      dividend_month: undefined,
      queryStatusList: [
        {
          status: 0,
          label: "待审核",
        },
        {
          status: 1,
          label: "待汇入",
        },
        {
          status: 2,
          label: "已汇入",
        },
      ],
      dataForm: {
        per_page: 10,
        page: 1,
        shop_id: '',
        partner_plan_id: '',
        partner_search: '',
      },
      fileList: [],
      edit_modal: {
        title: '生成合伙人分红订单',
        visible: false,
        form: { actual_dividend_amount: '', dividend_order_id: '' },
        data: '',
        dataMonth: ''
      },
      modal: {
        title: '分红结算',
        visible: false,
        url_loading: false,
        form: {
          medias: '',
          dividend_order_partner_ids: '',
          settlement_type: '',
          content: '',
          partner_plan_id: '',
          shop_id: '',
        },
        money: '',
        dataMonth: [],
      },
      money_modal: {
        data: '',
        visible: false,
        form: { actual_dividend_amount: '', dividend_order_id: '' },
      },
      table: {
        tableData: [],
        tableDateList: [], settlement_month: '',
        loading: false,
        selectedRowKeys: [],
        total_subscribed_shares: 0,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
    };
  },
  async created() {
    var now = new Date();
    var nowYear = now.getFullYear();
    var nowMonth = now.getMonth();
    console.log("nowYear :>> ", nowYear);
    console.log("nowMonth :>> ", nowMonth);
    let num = nowMonth < 10 ? '0' + nowMonth : nowMonth
    this.dividend_month = nowYear + "-" + num;
    // 获取门店
    await this.getInstitutionProvince();
  },
  watch: {
    'dataForm.partner_plan_id': {
      handler(val) {
        this.getList();
      },
      deep: true
    },
    'dataForm.shop_id': {
      handler(val) {
        this.getPartnerIndexAll();
      },
      deep: true
    },
  },
  methods: {
    moment,
    // 单元格上色
    // renderTimeBackground(record, index) {
    //   if (record.date_list.length) {
    //     console.log('record :>> ', record);
    //     return { class: "table-color-success" }
    //   } else {
    //     return { class: "table-color-error" }
    //   }
    // },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    getRowBackGround(recordAlarm) {
      if (this.checkedList.includes(recordAlarm.id)) {
        return "table-color-default"
      } else {
        return ' '
      }
    },
    setMonth(date, dateString) {
      this.dividend_month = dateString;
      this.getMonthList();
    },
    //上传
    beforeUploadImg(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        if (fileList.length > 1) {
          self.$message.error('每次只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    handleChange({ fileList }) {
      this.fileList = fileList.map(it => {
        const { file_url } = it.url || it.response?.data || {}
        if (file_url) {
          return { ...it, url: file_url }
        } else {
          return { ...it }
        }
      })
      console.log('info :>> ', this.fileList);
    },
    // 结算
    // 回显状态
    reg(item, val) {
      let str = item.find(v => v.dividend_order_id == val.id)
      return str?.status_text || '--'
    },
    // 回显金额
    regMoney(item, val) {
      let str = item.find(v => v.dividend_order_id == val.id)
      return str?.predict_dividend_amount || '0.00'
    },
    // 转换数字
    repNum(str) {
      let num = str.replace(/\-/g, "");
      return Number(num)
    },
    // 回显合计
    setAllmoney(item) {
      console.log('item :>> ', item);
      console.log('this.getlastMonth(this.table.settlement_month) :>> ', this.getlastMonth(this.table.settlement_month));
      let monNum = this.getlastMonth(this.table.settlement_month);
      let str = [];
      this.table.tableDateList.forEach(v => {
        if (this.repNum(v.dividend_month) <= this.repNum(monNum)) {
          str.push(v.id)
        }
      })
      let list = []
      str.forEach(val => {
        let idStr = item.find(v => v.dividend_order_id == val)
        console.log('idStr :>> ', idStr);
        if (idStr && idStr.status) {
          list.push(idStr)
        }
      });
      let price = list.reduce((sum, e) => sum + Number(e.status == 2 ? e.predict_dividend_amount : 0), 0)
      return price.toFixed(2) || '0.00'
    },
    // 获取当前日期的上一个月
    getlastMonth(date) {
      let nowdate = new Date(date); //下次校验日期时间戳
      let d1 = nowdate.setDate(nowdate.getDate() - 30); //一个月前的日期
      d1 = new Date(d1); //一个月前的时间戳
      let str = "";
      str += d1.getFullYear() + "-"; //获取当前年份
      //月
      d1.getMonth() < 10
        ? (str += "0" + (d1.getMonth() + 1))
        : (str += d1.getMonth() + 1);
      return str
    },
    // 结算月份
    getMonthStr(ids, arr) {
      if (!ids.length) return
      var str = [];
      ids.forEach(num => {
        let monStr = arr.find(val => val.id == num)
        if (monStr) {
          str.push(monStr.dividend_month)
        }
      });
      console.log('ids :>> ', ids);
      console.log('arr :>> ', arr);
      console.log('arr :>> ', str);
      return str[0] + ' ~ ' + str[str.length - 1]
      // this.getlastMonth(this.table.settlement_month)
    },
    // 全选
    onChangeCheckboxAll(e) {
      if (e.target.checked) {
        const item = this.table.tableData;
        console.log('item :>> ', item);
        var arr = [];
        item.forEach(num => {
          if (!num.date_list.length) return
          let index = num.date_list.findIndex(val => val.status_text == '待结算')
          if (index != -1) {
            arr.push(num.id)
          }
        });
        this.checkedList = arr
      } else {
        this.checkedList = []
      }
    },
    // 判断是否可以全选结算
    setDisAll() {

    },
    // 判断此行是否可以选择结算
    setDis(item) {
      console.log('item :>> ', item);
      if (item.bg_show.length) {
        let index = item.date_list.findIndex(val => val.status_text == '待结算')
        if (index != -1) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    onChangeCheckbox(item, e) {
      if (e.target.checked) {
        this.checkedList.push(item.id)
      } else {
        console.log('this.checkedList :>> ', this.checkedList);
        console.log('item.id :>> ', item.id);
        let index = this.checkedList.findIndex(item => item == item.id)
        this.checkedList.splice(index, 1)
      }
      console.log('this.checkedList :>> ', this.checkedList);
    },
    settleAccounts() {
      const item = this.table.tableData
      console.log('item :>> ', item);
      if (!this.checkedList.length) return this.$message.warning('请选择结算订单！');
      var money = 0;
      var arr = [];
      var dataMonth = [];
      this.checkedList.forEach(num => {
        let str = item.find(v => v.id == num)
        console.log('str :>> ', str);

        let monNum = this.getlastMonth(this.table.settlement_month);
        let str1 = [];
        this.table.tableDateList.forEach(v => {
          if (this.repNum(v.dividend_month) <= this.repNum(monNum)) {
            str1.push(v.id)
          }
        })
        let list = []
        str1.forEach(val => {
          let idStr = str.date_list.find(v => v.dividend_order_id == val)
          list.push(idStr)
        });
        list.forEach(val => {
          arr.push(val.id)
          if (val.status == 2) {
            dataMonth.push(val.dividend_order_id)
          }
        })
        // str.date_list.forEach(val => {
        //   console.log('val :>> ', val);
        //   arr.push(val.id)
        //   if (val.status == 2) {
        //     dataMonth.push(val.dividend_order_id)
        //   }
        // })
        let price = list.reduce((sum, e) => sum + Number(e.status == 2 ? e.predict_dividend_amount : 0), 0)
        money = Number(money) + Number(price)
      });
      console.log('money :>> ', money);
      this.modal.form.dividend_order_partner_ids = arr.toString();
      this.modal.form.partner_plan_id = this.dataForm.partner_plan_id;
      this.modal.form.shop_id = this.dataForm.shop_id;
      this.modal.money = money.toFixed(2);
      this.modal.dataMonth = dataMonth;
      this.modal.visible = true;
    },
    setModal() {
      let list = [];
      this.fileList.forEach(val => {
        list.push(val.url)
      })
      this.modal.form.medias = list.toString();
      if (!this.modal.form.settlement_type) return this.$message.warning('请选择结算方式！')
      // if (!this.modal.form.content) return this.$message.warning('请填写备注！')
      // if (!this.modal.form.medias) return this.$message.warning('请上传图片！')
      partnerFhChainSettleDividend(this.modal.form).then(res => {
        console.log(res.data, "data");
        this.modal.visible = false;
        this.getList();
      })
    },
    // 修改实际分红金额
    editItem(item) {
      console.log('item :>> ', item);
      this.money_modal.data = item;
      this.money_modal.form.dividend_order_id = item.id;
      this.money_modal.form.actual_dividend_amount = item.actual_dividend_amount;
      this.money_modal.visible = true
    },
    setModalVisible() {
      partnerFhChainSave(this.money_modal.form).then(res => {
        console.log(res.data, "data");
        this.money_modal.visible = false,
          this.getList();
      })
    },
    // 获取门店
    async getInstitutionProvince() {
      getCurrentList({ brand_id: localStorage.getItem('global_brand_id') }).then(res => {
        this.shopList = res.data;
        if (res.data.length) {
          this.dataForm.shop_id = res.data[0].id;
        } else {
          this.dataForm.shop_id = undefined;
        }
        console.log('this.shopList :>> ', this.shopList);
      })
    },
    async getPartnerIndexAll() {
      getPlanAll({ institution_id: this.dataForm.shop_id }).then(res => {
        console.log(res.data, "data");
        this.schemeList = res.data
        if (this.schemeList.length) {
          this.dataForm.partner_plan_id = this.schemeList[0].id;
        } else {
          this.dataForm.partner_plan_id = undefined;
        }
        this.getList()
      })
    },
    // 判断是否可选
    setBgShow(item, date_list) {
      console.log('item :>> ', item);
      console.log('this.getlastMonth(this.table.settlement_month) :>> ', this.getlastMonth(this.table.settlement_month));
      let monNum = this.getlastMonth(this.table.settlement_month);
      let str = [];
      date_list.forEach(v => {
        if (this.repNum(v.dividend_month) <= this.repNum(monNum)) {
          if (v.id) {
            str.push(v.id)
          }
        }
      })
      let list = []
      str.forEach(val => {
        let idStr = item.find(v => v.dividend_order_id == val)
        if (idStr != -1) {
          list.push(idStr.dividend_order_id)
        }
      });
      return list
    },
    // 获取列表
    async getList() {
      let self = this;
      if (!self.dataForm.partner_plan_id) return this.$message.warning('当前门店未参与合伙人方案');
      self.table.selectedRowKeys = []
      self.table.tableData = []
      self.table.tableDateList = [];
      self.table.loading = true;
      const params = {
        ...self.dataForm,
      };
      return partnerFhPartnerList(params)
        .then((res) => {
          console.log(res.data, "data");
          const { data = [], date_list = [], total = 0, settlement_month, total_subscribed_shares = 0 } = res.data;
          self.table.settlement_month = settlement_month;
          self.table.total_subscribed_shares = total_subscribed_shares;
          self.table.tableDateList = date_list;
          self.table.tableData = data.map(val => {
            val.bg_show = this.setBgShow(val.date_list, date_list);
            return val
          });
          let list = []
          date_list.forEach((val, index) => {
            list.push({
              dataIndex: "_" + index,
              key: "_" + index,
              align: 'center',
              width: date_list.length > 5 ? 200 : '',
              slots: { title: "_" + index },
              scopedSlots: { customRender: "c_" + index },
              // customCell: self.renderTimeBackground
            })
          })
          self.columns = [{
            dataIndex: "user",
            key: "user",
            slots: { title: '_star' },
            fixed: 'left',
            width: 200,
            scopedSlots: { customRender: 'user' },
          }];
          self.columns = self.columns.concat(list, self.columns_end);
          self.table.pagination = Object.assign(
            {},
            { pageSize: this.dataForm.per_page, current: this.dataForm.page },
            {
              total: total,
            }
          );
        })
        .finally((r) => {
          self.table.loading = false;
        });
    },
    onSelectChange(selectedRowKeys) {
      console.log(selectedRowKeys);
      this.table.selectedRowKeys = selectedRowKeys;
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      const { field, order } = sorter;
      this.dataForm = Object.assign(this.dataForm, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getList();
    },
    // 月份
    async getMonthList() {
      partnerFhChainMonthDetail({ dividend_month: this.dividend_month, partner_plan_id: this.dataForm.partner_plan_id, shop_id: this.dataForm.shop_id }).then(res => {
        console.log('res11111111 :>> ', res);
        this.edit_modal.dataMonth = res.data;
        this.edit_modal.form.actual_dividend_amount = res.data.actual_dividend_amount;
        this.edit_modal.form.dividend_order_id = res.data.id;
      })
    },
    //生成订单
    async setDetail() {
      partnerFhChainMonthDetail({ dividend_month: this.dividend_month, partner_plan_id: this.dataForm.partner_plan_id, shop_id: this.dataForm.shop_id }).then(res => {
        this.edit_modal.dataMonth = res.data;
        this.edit_modal.form.actual_dividend_amount = res.data.actual_dividend_amount;
        this.edit_modal.form.dividend_order_id = res.data.id;
        partnerFhChainCreateDetail({ partner_plan_id: this.dataForm.partner_plan_id, shop_id: this.dataForm.shop_id }).then(res => {
          this.edit_modal.data = res.data;
          this.edit_modal.visible = true
        })
      }).finally(r => {
        console.log('r :>> ', r);
      })
    },
    handleOkEdit() {
      let self = this
      if (self.edit_modal.dataMonth.is_dividend == 1) {
        self.$confirm({
          title: self.dividend_month + '合伙人分红订单已生成',
          icon: () => self.$createElement('a-icon', {
            props: {
              type: 'exclamation-circle',
              theme: 'filled'
            },
          }),
          content: h =>
            <div>
              是否重新生成订单并替换?
              说明: <br />
              1、当生成合伙人分红订单时,选择月份已经生成过合伙人分红订单,点击确定按钮时,弹出替换合伙人分红订单确认弹窗; <br />
              2、当对生成的分红订单进行替换时,合伙人小程序展示替换后的预计分红金额;
            </div>,
          okText: '确定',
          cancelText: '取消',
          onOk() {
            const hide = self.$message.loading('正在提交..', 0)
            partnerFhChainCreate(self.edit_modal.form).then((res) => {
              self.edit_modal.visible = false
              self.getList()
            }).finally(r => {
              hide()
            })
          },
          onCancel() {
          },
        });
      } else {
        const hide = self.$message.loading('正在提交..', 0)
        partnerFhChainCreate(self.edit_modal.form).then((res) => {
          self.edit_modal.visible = false
          self.getList()
        }).finally(r => {
          hide()
        })
      }
    }
  },
};
</script>
<style lang="less" scoped>
.twoh3 {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 20px;
    margin-right: 12px;
  }
}
.w-200 {
  text-align: left;
}
.item_cards {
  background: #fafcfc;
  border-radius: 8px;
  border: 1px solid @borderColorBase;
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 22px;
  justify-content: space-evenly;
  .toptext {
    font-size: 14px;
    font-weight: 400;
    color: #4d5e59;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .b-r {
    padding-right: 8%;
    border-right: 1px solid @borderColorBase;
    // border-left: 1px solid @primaryColor;
  }
  .bottomtext {
    color: @baseColor11;
    font-size: 22px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    line-height: 26px;
  }
}
/deep/ .ant-tag {
  color: @baseColor11;
  border: 1px solid @baseColor11;
}
.twoh2 {
  display: flex;
  margin-bottom: 7px;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @baseColor11;

    border-radius: 1px;
    margin: 6px 8px 0 0;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 22px;
  }
}
/deep/.ant-row-flex-bottom {
  align-items: center;
}
.y-table {
  /deep/.ant-table-body{
    overflow-x: auto !important;
  }
  /deep/.ant-table-thead > tr > th {
    padding-top: 0px;
  }
  /deep/.ant-input-affix-wrapper {
    .ant-input-prefix {
      left: 3px;
    }
    .ant-input:not(:first-child) {
      padding-left: 22px;
    }
  }
  .p-16-td {
    padding: 25px 16px !important;
    position: relative;
    display: block !important;
  }
  /deep/.ant-table-tbody > tr > td {
    padding: 0 !important;
  }
  /deep/.ant-table-footer {
    background: rgba(224, 248, 246, 0);
    border-bottom: 0;
    text-align: right;
  }
  /deep/.ant-table-footer::before {
    top: 0;
  }
  font-family: PingFangSC-Medium, PingFang SC;
  .title-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: @fontColor1;
  }
  .border-top {
    // border-top: 1px solid #d1e3e1;
    padding: 8px;
  }
  .header-text {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 20px;
  }
  .phone-14 {
    margin-top: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(45, 56, 53, 0.8);
  }
  .phone {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(45, 56, 53, 0.8);
  }
  /deep/ .ant-table-row-cell-last,
  /deep/.ant-table .ant-table-thead > tr > th {
    background: rgba(211, 251, 249, 0.23);
    box-shadow: inset 0px -5px 0px 0px rgba(193, 212, 211, 0.31);
  }
  /deep/.ant-table-fixed-left table {
    border-right: 2px solid rgba(198, 225, 224, 0.26);
  }
  /deep/ .ant-table-fixed-right table {
    border-left: 2px solid rgba(198, 225, 224, 0.26);
  }
  /deep/ .ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
    box-shadow: 6px 0 6px -4px rgba(198, 225, 224, 0.26);
  }
  /deep/ .ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
    box-shadow: -4px 0 6px 2px rgba(198, 225, 224, 0.26);
  }
  /deep/ .ant-checkbox-inner {
    top: -1px;
  }
  /deep/.ant-input {
    width: 162px;
    border-radius: 0px;
    border-top: 0 !important;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #d1e3e1;
    background: none !important;
  }
  /deep/.ant-input:focus,
  .ant-input:hover,
  .ant-select-selection:focus,
  .ant-select-selection:hover,
  .ant-cascader-picker:hover .ant-cascader-picker-focused,
  .ant-cascader-picker-label:hover {
    border-bottom: 1px solid #0bc7b9;
    box-shadow: none;
  }
  /deep/.ant-table-row > td:nth-child(1),
  /deep/.ant-table-thead > tr:first-child > th:first-child {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  /deep/ .ant-table .ant-table-body .ant-table-thead > tr > th {
    border-bottom: 0px !important;
  }
}
/deep/.ant-btn-icon-only.ant-btn-sm {
  width: 21px;
  height: 21px;
  font-size: 12px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(173, 207, 203, 0.35),
    inset 0px -3px 0px 0px #dbeae8;
  border: 1px solid #bacbc7;
}
.theme-grey {
  font-size: 16px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #565f5c;
}
</style>
<style lang="less">
.y-table {
  .table-color-error {
    background: #f7f7f7 !important;
    box-shadow: none !important;
  }
  .table-color-default {
    .table-color-success {
      background: #e2faf8;
      box-shadow: inset 0px -2px 0px 0px #0bc7b9;
    }
    .ant-table-fixed-columns-in-body {
      background: rgba(224, 248, 246, 0) !important;
      box-shadow: none;
    }
  }
  .table-manage .ant-table-thead > tr > th{
    border-bottom: 0 !important;
  }
  .ant-table-fixed-left, .ant-table-fixed-right{
    top: -1;
  }
  .table-color-default > td:first-child {
    background: rgba(224, 248, 246, 0) !important;
    box-shadow: none;
  }
  .table-color > td:first-child {
    background: rgba(224, 248, 246, 0) !important;
    box-shadow: none;
  }
  .table-color-waring > td {
    background: #f7f7f7;
    box-shadow: none;
    .ant-table-fixed-columns-in-body {
      background: rgba(224, 248, 246, 0) !important;
      box-shadow: none;
    }
  }
}
.left-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(45, 56, 53, 0.8);
}
.line-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.plus-icon {
  width: 24px;
  height: 24px;
  padding-top: 3px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(173, 207, 203, 0.35),
    inset 0px -3px 0px 0px #dbeae8;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #bacbc7;
}
.plus-icon-two {
  font-size: 13.8px !important;
  text-align: center;
  padding-top: 0px !important;
  display: inline-block;
  color: @fontColor1;
  font-weight: 400;
}
</style>